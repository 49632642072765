<template>
  <div @click="hideFullscreen" class="fullscreen-image">
    <span style="right: 0; position: fixed; top: 0; padding-top: 10px; padding-right: 20px; font-size: 30px; color: white;">&times;</span>
    <img :src="fullscreenImageURL" alt="" />
  </div>
</template>

<script>

export default {
  name: 'ChatFullscreenItem',
  components: { },
  props: {
    fullscreenImageURL: String
  },
  data () {
    return {}
  },
  beforeCreate () {
  },
  methods: {
    hideFullscreen() {
      this.$emit('hideClicked', {})
    }
  },
  computed: {}
}
</script>

<style lang="scss">

</style>
